(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
Raven.config('https://4521f9dfbde94bd4b0e0316ee6b53a19@sentry.io/291621', {
  ignoreUrls: [/localhost/, /autoo\.local/, /dev\.autoo\.ru/],
  autoBreadcrumbs: {
    console: false,
  },
}).install();

Raven.context(() => {
  const features = [];
  if (!('Promise' in window)) {
    features.push('Promise');
  }

  if (features.length) {
    const s = document.createElement('script');
    s.src = `https://polyfill.io/v2/polyfill.min.js?features=${features.join(',')}&flags=gated,always&ua=chrome/50`;
    s.async = true;
    document.head.appendChild(s);
  }

  const city = $('.city_link, .footer_cities_link');

  const resetBtn = $('.reset_btn, .result_filter_btn');
  const applyButton = $('.apply_btn');

  const carBrandName = $('.filter_auto_item_mark');
  const carBrandSelect = $('.filter_car_select');
  const filterOptions = $('.filter_item input:checkbox');
  const sorting = $('#select_results');

  function setCar() {
    const ACTIVE = 'active';

    const autoSelectBtn = $('filter_auto_select_btn');
    const selectField = $('.select_field_item');
    const autoItems = $('.filter_item.filter_auto_item');
    const filterAutoItem = $('.filter_auto_item');

    if (initialState.hasOwnProperty('carBrandSeo')) {
      const exists = $(`.filter_car_select option[value=${initialState.carBrandSeo}]`).length !== 0;
      if (exists) {
        carBrandSelect.val(initialState.carBrandSeo);
        autoSelectBtn.show();
        autoItems.removeClass(ACTIVE);
        filterAutoItem.removeClass(ACTIVE);
        selectField.addClass(ACTIVE);
      } else {
        carBrandSelect.val('no-car-selected');
        autoSelectBtn.hide();
        autoItems.removeClass(ACTIVE);
        selectField.removeClass(ACTIVE);
        $(`.filter_auto_item[data-titleseo="${initialState.carBrandSeo}"]`).addClass(ACTIVE);
      }
    } else {
      carBrandSelect.val('no-car-selected');
      autoSelectBtn.hide();
      autoItems.removeClass(ACTIVE);
      selectField.removeClass(ACTIVE);
      filterAutoItem.removeClass(ACTIVE);
    }
  }

  function getSearchUrlFromCurrentState() {
    let carBrandSeo = '';
    if (initialState.carBrandSeo) {
      carBrandSeo = `/${initialState.carBrandSeo}`;
    }
    let query = '';
    let symbol = '?';

    if (!initialState.filterOptions) {
      initialState.filterOptions = {};
    }

    initialState.filterOptions.metro = initialState.metro;
    initialState.filterOptions.district = initialState.district;

    for (const k in initialState) {
      if ((k === 'citySeo') || (k === 'titleSeo') || (k === 'carBrandSeo')) {
        continue;
      }
      if (k === 'filterOptions') {
        for (const filterOption in initialState.filterOptions) {
          for (const filterParam in initialState.filterOptions[filterOption]) {
            query += `${symbol + filterOption}[]=${initialState.filterOptions[filterOption][filterParam]}`;
            symbol = '&';
          }
        }
      }
      if (!initialState.citySeo) {
        initialState.citySeo = 'moskva';
      }
    }
    return `/${initialState.citySeo}/${initialState.titleSeo}${carBrandSeo}${query}`;
  }

  function goToNewState(page) {
    let url;
    switch (page) {
      case 'index': {
        url = initialState.citySeo ? `/${initialState.citySeo}` : '/';
        break;
      }
      case 'feed': {
        url = getSearchUrlFromCurrentState();
        break;
      }
      case 'company': {
        url = `/company/${initialState.currentCompany}`;
        break;
      }
      case 'categoriesGeo': {
        url = `/${initialState.citySeo}/geo`;
        break;
      }
      case 'categoriesBrands': {
        url = `/${initialState.citySeo}/brands`;
        break;
      }
      case 'categoriesServices': {
        url = `/${initialState.citySeo}/services`;
        break;
      }
      default: {
        Raven.captureMessage('Go to new state. Unknown page', {
          level: 'error',
          extra: {
            page,
          },
        });
        url = '/';
      }
    }
    window.location.href = url;
  }

  $('.filter_auto_select_btn').click((event) => {
    const device = $(event.currentTarget).attr('data-device');
    delete initialState.carBrandSeo;
    setCar();
    if (device === 'desktop') {
      goToNewState('feed');
    }
  });

  carBrandName.click((event) => {
    const selectedValue = $(event.currentTarget).attr('data-titleseo');
    const device = $(event.currentTarget).attr('data-device');
    if ($(event.currentTarget).parent().hasClass('active')) {
      delete initialState.carBrandSeo;
    } else {
      initialState.carBrandSeo = selectedValue;
    }
    setCar();
    if (device === 'desktop') {
      goToNewState('feed');
    }
  });

  carBrandSelect.change((event) => {
    const selectedValue = event.target.value;
    const device = $(event.currentTarget).attr('data-device');
    if (selectedValue === 'no-car-selected') {
      delete initialState.carBrandSeo;
    } else {
      initialState.carBrandSeo = selectedValue;
    }
    setCar();
    if (device === 'desktop') {
      goToNewState('feed');
    }
  });

  filterOptions.change((event) => {
    const categoryName = $(event.currentTarget).attr('data-type');
    const valueName = $(event.currentTarget).attr('data-service');
    const device = $(event.currentTarget).attr('data-device');
    if ($(event.currentTarget).is(':checked')) {
      initialState.filterOptions[categoryName].push(valueName);
    } else {
      const index = initialState.filterOptions[categoryName].indexOf(valueName);
      if (index > -1) {
        initialState.filterOptions[categoryName].splice(index, 1);
      }
    }
    if (device === 'desktop') {
      goToNewState('feed');
    }
  });

  resetBtn.click((event) => {
    event.preventDefault();
    initialState.filterOptions = [];
    delete initialState.carBrandSeo;
    delete initialState.metro;
    delete initialState.district;
    goToNewState('feed');
  });

  applyButton.click((event) => {
    event.preventDefault();
    goToNewState('feed');
  });

  const getCookie = (name) => {
    const cookieName = name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1');
    const matches = document.cookie.match(new RegExp(`(?:^|; )${cookieName}=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  };

  const setCookie = (name, value, expires) => {
    const defaultTime = expires || 60 * 60 * 24 * 30 * 1000;
    const date = new Date(new Date().getTime() + defaultTime);
    document.cookie = `${name}=${value}; path=/; expires=${date.toUTCString()}`;
  };

  window.autoo = { reloadPage: goToNewState, yaCounter: undefined };

  const setCurrentCity = (chosenCity) => {
    if (initialState.citySeo === chosenCity) {
      return;
    }
    initialState.citySeo = chosenCity;
    initialState.district = [];
    initialState.metro = [];
    goToNewState(currentPage);
  };

  city.click((event) => {
    event.preventDefault();
    const chosenCity = $(event.currentTarget).data('service');
    setCookie('user_city', chosenCity);
    setCurrentCity(chosenCity);
  });

  function updateComponents() {
    setCar();
    let input;
    if (initialState.hasOwnProperty('filterOptions')) {
      for (const filterOption in initialState.filterOptions) {
        for (const filterParam in initialState.filterOptions[filterOption]) {
          input = $(`input:checkbox[data-type="${filterOption}"][data-service="${initialState.filterOptions[filterOption][filterParam]}"]`);
          input.prop('checked', true);
          input.parent().toggleClass('active');
        }
      }
    }
    if (initialState.hasOwnProperty('sortby')) {
      sorting.val(initialState.sortby);
    }
  }

  updateComponents();

  require('../modules/_search');
  require('../modules/_audioplayer');
  require('../modules/_partners_contact_form');
  require('../modules/_profileInfoText');
  const Modal = require('../modules/_modal');
  const Accordeon = require('../modules/_accordeon');
  const Dropdown = require('../modules/_dropdown');
  const yaCounter = require('../modules/_yaCounter');
  window.autoo.yaCounter = yaCounter;
  require('../modules/_image_gallery');
  require('../modules/_review_gallery')();
  require('../modules/_call_tooltip');
  const checkBoxActive = require('../modules/_checkbox_active');
  const modalAskScenario = require('../modules/_modal_ask_scenario');
  require('../modules/_filterList')();
  require('../modules/_filterListModal')(setCar);
  require('../modules/_feedContentSwitch')();
  require('../modules/_svg4everybody')();
  require('../modules/_chosen')();
  require('../modules/_modal_review')();

  const starRating = require('../modules/_star_rating');
  require('../modules/_request_map')();
  require('../modules/_modal_map')();
  require('../modules/_review_form_validation')();
  const Tabs = require('../modules/_tab.js');
  require('../modules/_review_page_form.js')();
  require('../modules/_ymap_review_page.js')();
  require('../modules/_widget.js')();
  require('../modules/_profileCommentsLogic.js')();
  require('../modules/_spyScroll.js');
  const feedCommentsUnfolder = require('../modules/_feed_comments_unfolder');
  const feedMetroClick = require('../modules/_feed_metro_click');
  const feedDistrictClick = require('../modules/_feed_district_click');
  require('../modules/_feed_more.js')(() => {
    feedMetroClick();
    feedDistrictClick();
    feedCommentsUnfolder();
  });
  require('../modules/_calls');
  require('../modules/_your_city')(setCurrentCity, getCookie, setCookie);

  starRating('#comment_reviev_stars_block');
  // Review Page
  starRating('#review_form_personal_block');
  starRating('#review_form_price_block');
  starRating('#review_form_comfort_block');
  starRating('#review_form_speed_block');


  new Accordeon('.profile_price_item', '.profile_price_head', 'active');
  new Accordeon('.feed_card_block', '.feed_question_title', 'active');
  new Accordeon('.profile_rating_comments_accordeon_item', '.profile_rating_comments_accordeon_trigger', 'active');

  new Modal({
    modal: '[data-modalname="question"]',
    animationOpen: 'fadeIn',
    animationClose: 'fadeOut',
    callBeforeOpen(el, btn) {
      modalAskScenario(el, btn);
    },
  });

  new Modal({
    modal: '[data-modalname="map"]',
    animationOpen: 'fadeIn',
    animationClose: 'fadeOut',
    callAfterOpen() {
      const modal = $('[data-modalname="map"]');
      const iframe = modal.find('#map_frame');
      const modalHeight = modal.find('.modal_inner').outerHeight() - 50;
      iframe.css({
        height: modalHeight,
      });

      $('[data-modalRedirect="question"]').on('click', (event) => {
        $(event.currentTarget).parents('.modal_wrap').find('.close-modal').click();
        $('button[data-modal="question"]').click();
      });
    },
  });

  new Modal({
    modal: '[data-modalname="city"]',
    animationOpen: 'fadeIn',
    animationClose: 'fadeOut',
    callAfterOpen(el) {
      const modal = $(`[data-modalname="${el}"]`);
      $('.city_locate_link').on('click', (event) => {
        event.preventDefault();
        $.ajax({
          url: '/geo/get-city-by-ip',
          success(response) {
            modal.find('.city_input.autocomplete_input').val(response.title);
            initialState.citySeo = response.titleSeo;
            goToNewState(currentPage);
          },
        });
      });
    },
  });

  new Modal({
    modal: '[data-modalname="review"]',
    animationOpen: 'fadeIn',
    animationClose: 'fadeOut',
    callAfterOpen() {
      starRating('#reviev_stars_block');
    },
  });

  new Modal({
    modal: '[data-modalname="header_search"]',
    animationOpen: 'fadeIn',
    animationClose: 'fadeOut',
  });

  new Modal({
    modal: '[data-modalname="filter"]',
    animationOpen: 'fadeIn',
    animationClose: 'fadeOut',
  });

  $('#profile_slick').slick({
    dots: true,
    mobileFirst: true,
  });

  checkBoxActive('.filter_block');

  $('#more_marks_trigger').on('click', (event) => {
    $(event.currentTarget).hide();
    $(event.currentTarget).next('#more_marks').show();
  });

  $('#see_all_prices').on('click', () => {
    $('.profile_price_list').slideToggle('fast');
  });

  $(() => {
    $('#typed').typed({
      stringsElement: $('#typed-strings'),
      typeSpeed: 25,
      startDelay: 0,
      backSpeed: 0,
      backDelay: 4000,
      loop: false,
      contentType: 'html',
      cursorChar: '|',
    });
  });

  $(document).on('click', '.feed_group_button_show_more_wrapper', (event) => {
    $(event.currentTarget).children('.view_more_rfg_list_items').prev('.rfg_list.hidden').slideToggle('fast');
    $(event.currentTarget).children('.view_more_rfg_list_items').toggleClass('rfg_list_opened');
    if (!$(event.currentTarget).children('.view_more_rfg_list_items').hasClass('rfg_list_opened')) {
      $('html, body').animate({
        scrollTop: $(event.currentTarget).parents('.result_feed_block').offset().top - 60,
      }, 'fast');
    }
  });

  $(document).ready(() => {
    const $pickupas = $('#pickupas');
    const $serviceSelect = $pickupas.find('[name="service"]');
    const $carBrandSelect = $pickupas.find('[name="mark"]');
    const $districtSelect = $pickupas.find('[name="district"]');

    $serviceSelect.click(() => {
      yaCounter.reachGoal('INDEX_SELECT_SERVICE_SHOW');
    });
    $serviceSelect.change(() => {
      yaCounter.reachGoal('INDEX_SELECT_SERVICE_CLICK');
    });
    $carBrandSelect.click(() => {
      yaCounter.reachGoal('INDEX_SELECT_CARBRAND_SHOW');
    });
    $carBrandSelect.change(() => {
      yaCounter.reachGoal('INDEX_SELECT_CARBRAND_CLICK');
    });
    $districtSelect.click(() => {
      yaCounter.reachGoal('INDEX_SELECT_DISTRICT_SHOW');
    });
    $districtSelect.change(() => {
      yaCounter.reachGoal('INDEX_SELECT_DISTRICT_CLICK');
    });
    $pickupas.submit((event) => {
      event.preventDefault();
      yaCounter.reachGoal('INDEX_BUTTON_CHOOSE_CLICK');

      if (initialState && initialState.heroFilter) {
        const serviceValue = initialState.heroFilter.service;
        const markValue = initialState.heroFilter.mark;
        const districtValue = initialState.heroFilter.district;

        const servicePath = (serviceValue && serviceValue !== '0') ? `${serviceValue}/` : 'all/';
        const markPath = (markValue && markValue !== '0') ? `${markValue}/` : '';
        const districtPath = (districtValue && districtValue !== '0') ? `?district=${districtValue}` : '';

        window.location.href = `/${initialState.citySeo}/${servicePath}${markPath}${districtPath}`;
      } else if (initialState && initialState.citySeo) {
        window.location.href = `/${initialState.citySeo}/all`;
      } else {
        throw new Error('Wrong initial state when submit hero form');
      }
    });
  });

  if (typeof document.activeElement.scrollIntoViewIfNeeded === 'function') {
    window.addEventListener('resize', () => {
      if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
        window.setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    });
  }

  if (!('ontouchstart' in document.documentElement)) {
    document.documentElement.className += 'no-touch';
  }

  $('[data-type=fullReviewBtn]').on('click', (event) => {
    $(event.currentTarget).hide();
    $(event.currentTarget).parent().next().slideDown('fast');
  });

  $('#hero_change_search_btn').on('click', (event) => {
    const $heroForm = $('.hero_form');
    const $heroDetailedSearch = $('.hero_detailed_search');
    const $textBlock = $(event.currentTarget).find('.hero_change_search_btn_text');
    const text = {
      formVisible: 'Быстрый подбор',
      detailedFormVisible: 'Расширенный поиск',
    };
    if ($heroForm.hasClass('hidden')) {
      $heroForm.removeClass('hidden');
      $heroDetailedSearch.addClass('hidden');
      $textBlock.text(text.formVisible);
    } else {
      $heroForm.addClass('hidden');
      $heroDetailedSearch.removeClass('hidden');
      $textBlock.text(text.detailedFormVisible);
    }
  });

  new Tabs({
    el: '#main_services_tabs',
    tabAnimation: 'fadeIn',
    beforeTabWillBeActive(evt) {
      return new Promise((resolve, reject) => {
        const mainServicesPlaceLink = $('#main_services_place_link');
        const currentLink = evt.currentTarget.getAttribute('href').slice(1);
        const image = $('.main_services_img');
        if (currentLink === 'district') {
          $(image).attr('src', `/img/index-maps/${initialState.citySeo}-city.png`);
          mainServicesPlaceLink.attr('href', `/${initialState.citySeo}/geo`);
          image.on('load', () => {
            resolve(image);
          });
        } else {
          $(image).attr('src', `/img/index-maps/${initialState.citySeo}-metro.png`);
          mainServicesPlaceLink.attr('href', `/${initialState.citySeo}/geo`);
          image.on('load', () => {
            resolve(image);
          });
        }
      });
    },
  });

  $('.category_sidebar').stick_in_parent({
    offset_top: 70,
  });

  $('.profile_rating_comments_stats').stick_in_parent({
    offset_top: 70,
  });

  new Dropdown({
    dropdowns: '.dropdown',
    openAnimationClass: 'fadeInDown',
    closeAnimationClass: 'fadeOutDown',
  });

  $('.profile_rating_comments_info').on('click', (event) => {
    $(event.currentTarget).parents('.profile_rating_comments_clients_total_head').toggleClass('active');
  });

  $('.profile_rating_comments_trust_popup').find('.profile_rating_comments_trust_popup_close_btn').on('click', (event) => {
    $(event.currentTarget).parents('.profile_rating_comments_clients_total_head').toggleClass('active');
  });

  $('#profile_rating_comments_clients_total_item_link').on('click', (event) => {
    event.preventDefault();
    $(event.currentTarget)
      .hide()
      .prev('ul.profile_rating_comments_clients_total_list')
      .find('.profile_rating_comments_clients_total_item')
      .slideDown('fast');
  });

  // See this link to understand --> https://github.com/select2/select2/issues/5075
  function fixCloseSelectionConflictWithBootstrap() {
    let MultipleSelection,
      old_bind;
    MultipleSelection = $.fn.select2.amd.require('select2/selection/multiple');
    old_bind = MultipleSelection.prototype.bind;
    MultipleSelection.prototype.bind = function () {
      old_bind.apply(this, arguments);
      return this.$selection.on('click', '.select2-selection__choice__remove', (evt) => {
        evt.stopPropagation();
        return false;
      });
    };
  }

  fixCloseSelectionConflictWithBootstrap();
});

},{"../modules/_accordeon":2,"../modules/_audioplayer":3,"../modules/_call_tooltip":4,"../modules/_calls":5,"../modules/_checkbox_active":6,"../modules/_chosen":7,"../modules/_dropdown":8,"../modules/_feedContentSwitch":9,"../modules/_feed_comments_unfolder":10,"../modules/_feed_district_click":11,"../modules/_feed_metro_click":12,"../modules/_feed_more.js":13,"../modules/_filterList":14,"../modules/_filterListModal":15,"../modules/_image_gallery":16,"../modules/_modal":17,"../modules/_modal_ask_scenario":18,"../modules/_modal_map":19,"../modules/_modal_review":20,"../modules/_partners_contact_form":21,"../modules/_profileCommentsLogic.js":22,"../modules/_profileInfoText":23,"../modules/_request_map":24,"../modules/_review_form_validation":25,"../modules/_review_gallery":26,"../modules/_review_page_form.js":27,"../modules/_search":28,"../modules/_spyScroll.js":29,"../modules/_star_rating":30,"../modules/_svg4everybody":31,"../modules/_tab.js":32,"../modules/_widget.js":33,"../modules/_yaCounter":34,"../modules/_ymap_review_page.js":35,"../modules/_your_city":36}],2:[function(require,module,exports){
module.exports = function (parentBlock, trigger, className) {


	if($(parentBlock).length){
		const accordeon = $(parentBlock),
			accordeonTrigger = $(trigger);

		accordeonTrigger.on('click', function () {
			$(this).parent(accordeon)
				.toggleClass(className);

		});
	}



	// const accordeon = $(parentBlock),
	// 	accordeonTrigger = $(trigger);
	//
	// accordeonTrigger.on('click', function () {
	// 	$(this).parent(accordeon)
	// 		.toggleClass(className);
	// });


	// console.log(accordeon)
	// console.log(accordeonTrigger)

};
},{}],3:[function(require,module,exports){
$(document).ready(() => {
  const audioState = {};

  function attachPlayer(playerId) {
    const audioplayer = document.getElementById(playerId);

    if (!audioplayer) {
      return;
    }

    const music = audioplayer.querySelector('.music');
    const pButton = audioplayer.querySelector('.pButton');
    const playhead = audioplayer.querySelector('.playhead');
    const timeline = audioplayer.querySelector('.timeline');

    const timelineWidth = timeline.offsetWidth - playhead.offsetWidth;

    let onplayhead = false;

    function getPosition(el) {
      return el.getBoundingClientRect().left;
    }

    function moveplayhead(event) {
      const newMargLeft = event.clientX - getPosition(timeline);

      if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
        playhead.style.marginLeft = `${newMargLeft}px`;
      }
      if (newMargLeft < 0) {
        playhead.style.marginLeft = '0px';
      }
      if (newMargLeft > timelineWidth) {
        playhead.style.marginLeft = `${timelineWidth}px`;
      }
    }

    function timeUpdate() {
      const playPercent = timelineWidth * (music.currentTime / music.duration);
      playhead.style.marginLeft = `${playPercent}px`;
      if (music.currentTime === music.duration) {
        pButton.className = 'pButton';
        pButton.className = 'play pButton';
      }
    }

    function mouseDown() {
      onplayhead = true;
      window.addEventListener('mousemove', moveplayhead, true);
      music.removeEventListener('timeupdate', timeUpdate);
    }

    function clickPercent(event) {
      return (event.clientX - getPosition(timeline)) / timelineWidth;
    }

    function mouseUp(event) {
      if (onplayhead === true) {
        moveplayhead(event);
        window.removeEventListener('mousemove', moveplayhead, true);
        // change current time
        music.currentTime = music.duration * clickPercent(event);
        music.addEventListener('timeupdate', timeUpdate);
      }
      onplayhead = false;
    }

    function play() {
      if (music.paused) {
        const playPromise = music.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            if (audioState.music) {
              audioState.music.pause();
              audioState.btn.className = 'play pButton';
            }
            audioState.music = music;
            audioState.btn = pButton;
            pButton.className = 'pause pButton';
          })
            .catch(() => {
              pButton.className = 'play pButton';
            });
        }
      } else {
        music.pause();
        audioState.music = null;
        audioState.btn = null;
        pButton.className = 'play pButton';
      }
    }

    pButton.addEventListener('click', play);

    music.addEventListener('timeupdate', timeUpdate);

    timeline.addEventListener('click', (event) => {
      moveplayhead(event);
      music.currentTime = music.duration * clickPercent(event);
    }, false);

    playhead.addEventListener('mousedown', mouseDown);
    window.addEventListener('mouseup', mouseUp);
  }

  attachPlayer('audioplayer1');
  attachPlayer('audioplayer2');
  attachPlayer('audioplayer3');
});

},{}],4:[function(require,module,exports){
$('document').ready(function () {

	/*
	setTimeout(function () {
		$('#call_tip').slideDown();
	}, 5000);


	$('[data-tooltip="close"]').on('click', function () {
		$(this).parents('.tooltip').slideUp('fast');
	});


	$('[data-modal="question"]').on('click', function () {
		$('.tooltip').slideUp('fast');
	});
	*/


});
},{}],5:[function(require,module,exports){
const yaCounter = require('../modules/_yaCounter');

$(document).on('click', '.as_phone_trig', function handler(event) {
  try {
    yaCounter.reachGoal('SHOW_TELEPHONE');

    event.stopPropagation();
    const $el = $(this);
    const imgLoader = "<img src='/img/loader_fb.svg' />";
    const filterOptions = initialState.hasOwnProperty('filterOptions') ? initialState.filterOptions : {};
    const citySeo = initialState.hasOwnProperty('citySeo') ? initialState.citySeo : null;
    const carBrandSeo = initialState.hasOwnProperty('carBrandSeo') ? initialState.carBrandSeo : null;
    const serviceSeo = initialState.hasOwnProperty('titleSeo') ? initialState.titleSeo : null;
    const companyTitleSeo = initialState.hasOwnProperty('currentCompany') ? initialState.currentCompany : null;
    const data = {
      id: $el.data('id'),
      session_id,
      params: {
        service_seo: serviceSeo,
        city_seo: citySeo,
        car_brand_seo: carBrandSeo,
        page_name: currentPage,
        filter_options: filterOptions,
        company_title_seo: companyTitleSeo,
      },
    };
    $.ajax({
      method: 'POST',
      url: '/api/call-tracking/bind-tracking-phone',
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      beforeSend() {
        $('.as_phone#open_p').removeAttr('id');
        $el.parent('.as_phone__inner').attr('id', 'open_p').empty().html(imgLoader);
      },
      success(response) {
        const linkCall = `<a href='tel:+${response.phone}' class='as_phone_dynamic_link'>${response.phone_show}</a>`;
        const $openedPhone = $('#open_p');
        $openedPhone.empty().html(linkCall);
        if ($openedPhone.children().html() === response.phone_show) {
          yaCounter.reachGoal('GET_CALLTRACKING_PHONE_OK');
        } else {
          yaCounter.reachGoal('CALLTRACKING_PHONE_FAIL_2');
          Raven.captureMessage('Get phone not equals', {
            level: 'error',
            extra: {
              htmlPhone: $openedPhone.children().html(),
              phoneShow: response.phone_show,
            },
          });
        }
        $('.as_phone__inner#open_p').removeAttr('id');
      },
      error(response) {
        const $openedPhone = $('#open_p');
        yaCounter.reachGoal('CALLTRACKING_PHONE_FAIL_1');
        Raven.captureMessage('Get phone error', {
          level: 'error',
          extra: {
            response,
          },
        });
        const linkCall = '<span>Неизвестная ошибка</span>';
        $openedPhone.empty().html(linkCall);
      },
    });
  } catch (e) {
    Raven.captureException(e);
  }
});

},{"../modules/_yaCounter":34}],6:[function(require,module,exports){
module.exports = function (checkBoxBlock) {

	const checkBoxWrap = $(checkBoxBlock);

	const checkBoxes = checkBoxWrap.find('input[type=checkbox]');

	checkBoxes.each(function( index ) {
		$(this).on('change', function(){

			if($(this).is(':checked')){
				$(this).parent('.form_label').addClass('active');
			}else {
				$(this).parent('.form_label').removeClass('active');
			}

			// console.log($(this).parent('.form_label').text());
		});
		// console.log( index + ": " + $( this ) );
	});
	// console.log(checkBoxes)

};

},{}],7:[function(require,module,exports){
module.exports = function () {
  // ///////////////
  // Services List

  const servicesList = $('#services_list');
  const servicesListResetBtn = $('#reset_services_list_btn');

  servicesList.chosen({
    no_results_text: ':( нет такой услуги',
    inherit_select_classes: true,
  });

  servicesList.on('change', () => {
    if ($('#services_list option:selected')) {
      $('.services_list_item').addClass('selected_service_item');
    }
  });

  servicesListResetBtn.on('click', () => {
    servicesList.find('option').prop('selected', false).trigger('chosen:updated');
    $('.services_list_item').removeClass('selected_service_item');
  });

  // ///////////////
  // Hero Services List
  const heroServicesList = $('#hero_services_list');
  heroServicesList.chosen({
    no_results_text: ':( нет такой услуги',
    inherit_select_classes: true,
  });
};

},{}],8:[function(require,module,exports){
const utilsLibrary = {
	onetime: function (node, type, callback) {
		node.addEventListener(type, function fn(e) {
			e.target.removeEventListener(e.type, fn);
			return callback(e);
		});
	},

	getParents: function (elem, selector) {

		// Element.matches() polyfill
		if (!Element.prototype.matches) {
			Element.prototype.matches =
				Element.prototype.matchesSelector ||
				Element.prototype.mozMatchesSelector ||
				Element.prototype.msMatchesSelector ||
				Element.prototype.oMatchesSelector ||
				Element.prototype.webkitMatchesSelector ||
				function (s) {
					var matches = (this.document || this.ownerDocument).querySelectorAll(s),
						i = matches.length;
					while (--i >= 0 && matches.item(i) !== this) {
					}
					return i > -1;
				};
		}

		// Setup parents array
		var parents = [];

		// Get matching parent elements
		for (; elem && elem !== document; elem = elem.parentNode) {

			// Add matching parents to array
			if (selector) {
				if (elem.matches(selector)) {
					parents.push(elem);
				}
			} else {
				parents.push(elem);
			}

		}

		return parents;

	}
};

class Dropdown {
	constructor(config) {
		this.dropdowns =  [].slice.call(document.querySelectorAll(config.dropdowns));
		this.openAnimationClass = config.openAnimationClass;
		this.closeAnimationClass = config.closeAnimationClass;
		this.init();
	}

	init() {
		this.dropdowns.forEach((el)=> {
			el.querySelector('[data-toggle=dropdown]').addEventListener('click', (e)=> {

				let el = e.currentTarget.parentNode;
				this.closeOpenedDropdowns(el, ()=> {
					if (e.currentTarget.parentNode.classList.contains('dropdown--hidden')) {
						this.openDropdown(e.currentTarget.parentNode);
					} else {
						this.closeDropdown(e.currentTarget.parentNode);
					}
				});
			});
		});

		window.addEventListener('click', (event)=> {

			// console.log(utilsLibrary.getParents(event.target, '.dropdown'));
			if (utilsLibrary.getParents(event.target, '.dropdown').length) {
				return;
			} else {
				this.dropdowns.forEach((el)=> {
					el.classList.add('dropdown--hidden');
				});
			}

		})
	}

	closeOpenedDropdowns(element, cb) {


		let currentDropdown = element;

		let otherDropdowns = Array.prototype.slice.call(this.dropdowns).filter((el)=> {
			return el !== currentDropdown;

		});
		otherDropdowns.forEach((el)=> {
			el.classList.add('dropdown--hidden');
		});


		cb();
	};

	openDropdown(dropdown) {
		let menu = dropdown.querySelector('.dropdown__menu');
		dropdown.classList.remove('dropdown--hidden');
		menu.classList.add(this.openAnimationClass);
		utilsLibrary.onetime(menu, 'animationend', (e)=> {
			menu.classList.remove(this.openAnimationClass);
		});
	}

	closeDropdown(dropdown) {
		let menu = dropdown.querySelector('.dropdown__menu');
		menu.classList.add(this.closeAnimationClass);
		utilsLibrary.onetime(menu, 'animationend', (e)=> {
			menu.classList.remove(this.closeAnimationClass);
			dropdown.classList.add('dropdown--hidden');
		});
	}

}
module.exports = Dropdown;

},{}],9:[function(require,module,exports){
module.exports = function(){

	$('.result_view_list').find('button').on('click',function(e){
		const btnDataSwitch = $(this).data('switch');
		$('.result_view_list').find('button').removeClass('active_view');
		$('.result_view_list').find('button[data-switch='+btnDataSwitch+']').addClass('active_view');
		$(this).addClass('active_view');

		if ( $(this).data('switch') === 'flow'){
			$('#map_frame_block').removeClass('active');
			$('#feed_content_flow').addClass('active');

		} else {
			$('#map_frame_block').addClass('active');
			$('#feed_content_flow').removeClass('active');
		}
	});

	// data-switch="flow"
};
},{}],10:[function(require,module,exports){
function addUnfoldCommentBehavior() {
  $('.feed_comment_wrapper a.disabled').each((index, el) => {
    const $el = $(el);
    $el.removeClass('disabled');
    $el.one('click', (event) => {
      event.preventDefault();
      const $this = $(event.currentTarget);
      $this.hide();
      $this.siblings('[data-type="more_sign"]').hide();
      $this.siblings('[data-type="secondary_text"]').show();
      $this.parent().siblings('.feed_comments_ref_wrapper').removeClass('hidden');
    });
  });
}

$(document).ready(() => {
  addUnfoldCommentBehavior();
});

module.exports = addUnfoldCommentBehavior;

},{}],11:[function(require,module,exports){
function addDistrictClickBehavior() {
  $('.result_feed_block .as_adres__adress-wrapper.disabled').each((index, el) => {
    const $el = $(el);
    const districts = $el.attr('data-district');

    if (!districts) return;

    $el.removeClass('disabled');
    $el.one('click', () => {
      initialState.metro = [];
      initialState.filterOptions.metro = initialState.metro;
      initialState.district = districts.split(',');
      initialState.filterOptions.district = initialState.district;
      window.autoo.reloadPage('feed');
    });
  });
}

$(document).ready(() => {
  addDistrictClickBehavior();
});

module.exports = addDistrictClickBehavior;

},{}],12:[function(require,module,exports){
function addMetroClickBehavior() {
  $('.result_feed_block .as_metro_item.disabled').each((index, el) => {
    const $el = $(el);
    $el.removeClass('disabled');
    $el.one('click', (event) => {
      initialState.district = [];
      initialState.filterOptions.district = initialState.district;
      initialState.metro = [$(event.currentTarget).attr('data-metro')];
      initialState.filterOptions.metro = initialState.metro;
      window.autoo.reloadPage('feed');
    });
  });
}

$(document).ready(() => {
  addMetroClickBehavior();
});

module.exports = addMetroClickBehavior;

},{}],13:[function(require,module,exports){
module.exports = function (callback) {
  const $link = $('#show_more_service');

  $link.on('click', (event) => {
    event.preventDefault();
    let feedLeft;
    let url;

    if (currentPage === 'feed') {
      feedLeft = parseInt(initialState.companyCount, 10);
    }

    const page = parseInt(initialState.page, 10) + 1;
    initialState.page = page;
    url = window.location.pathname;

    const query = window.location.search.substr(1);
    const result = [`page=${page}`, `limit=${initialState.limit}`];

    if (query !== '') {
      query.split('&').forEach((part) => {
        const item = part.split('=');
        if (item[0] !== 'page') {
          result.push(`${item[0]}=${decodeURIComponent(item[1])}`);
        }
      });
    }
    url += `?${result.join('&')}`;
    $.ajax({
      dataType: 'html',
      url,
      success(responseData) {
        $(responseData).insertBefore('.show_more_data');
        feedLeft -= parseInt(initialState.limit, 10) * page;
        if (feedLeft === 0 || feedLeft <= 0) {
          $link.remove();
        }
        $link.text(`Загрузить еще ${feedLeft > initialState.limit ? initialState.limit : feedLeft} сервисов из ${feedLeft}`);
        callback();
      },
    });
  });
};

},{}],14:[function(require,module,exports){
module.exports = function () {
	const filterTrigger = $('.filter_title');
	const filter_auto_item_mark = $('.filter_auto_item_mark');
	const selectMark = $('#mark');
	const selectResetBtn = $('#filter_auto_select_btn');

	filterTrigger.on('click', function (e) {
		e.stopPropagation();
		$(this).parent('.filter_section').toggleClass('active');
		// $(this).next('.filter_list').slideToggle('fast');
	});


	filter_auto_item_mark.on('click', function (e) {
			e.stopPropagation();

			if($(this).parent('.filter_auto_item').hasClass('active')){
				$(this).parent('.filter_auto_item').removeClass('active');
			}else {
				$('.filter_auto_list').find('.filter_auto_item').removeClass('active');
				$(this).parent('.filter_auto_item').addClass('active');
			}

			selectMark.prop('selectedIndex',0);
			$('.select_field_item').removeClass('active');

		// $('.filter_auto_list').find('.filter_auto_item').removeClass('active');
		// $(this).parent('.filter_auto_item').addClass('active');

		// $('.filter_auto_list').find('.filter_auto_item').removeClass('active');
		// $('.select_field_item').removeClass('active');
		// $(this).parent('.filter_auto_item').addClass('active');
		// selectMark.prop('selectedIndex',0);
	});

	selectMark.on('change', function (e) {
		$('.filter_auto_list').find('.filter_auto_item').removeClass('active');
		$('.select_field_item').addClass('active');

	});

	// selectResetBtn.on('click', function (e) {
	// 	e.stopPropagation();
	// 	$('.select_field_item').removeClass('active');
	// 	$('.filter_auto_list').find('.filter_auto_item').removeClass('active');
	// 	selectMark.prop('selectedIndex',0);
	// });

};
},{}],15:[function(require,module,exports){
module.exports = function (callback) {

	const selectMark = $('#mark2');
	const selectResetBtn = $('#filter_auto_select_btn2');

	$('.modal_filter .filter_auto_item_mark').on('click',function(e){
		selectMark.prop('selectedIndex',0);
		initialState.carBrandSeo = e.currentTarget.dataset.titleseo;
		callback();
	});


	selectMark.on('change', function (e) {
		$('.filter_auto_list').find('.filter_auto_item').removeClass('active');
		$(this).parents('.select_field_item').addClass('active');
		// selectMark.prop('selectedIndex',0);
		// console.log($(this).text())

	});

	// selectResetBtn.on('click', function (e) {
	// 	$('.select_field_item').removeClass('active');
	// 	$('.filter_auto_list').find('.filter_auto_item').removeClass('active');
	// 	selectMark.prop('selectedIndex',0);
	// });

};
},{}],16:[function(require,module,exports){
$('a.profile_gallery_link').on('click',function(e){
	e.preventDefault();
	var index = $('#profile_gallery').find('.profile_gallery_link').index( this );
	var pswpElement = document.querySelectorAll('.pswp')[0];
	var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, {index: index});
	gallery.init();
});

},{}],17:[function(require,module,exports){
class Modal {
	constructor(config = {}) {
		this.modalWindow = $(config.modal);
		this.modalDataName = this.modalWindow.data('modalname');
		this.modalTriggers = $('[data-modal=' + this.modalDataName + ']');
		this.animationOpen = config.animationOpen;
		this.animationClose = config.animationClose;
		this.callBeforeOpen = config.callBeforeOpen;
		this.callAfterOpen = config.callAfterOpen;
		this.callAfterClose = config.callAfterClose;
		this.openStatus = false;
		this.animationEnd = "animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd";
		this.closeModal = this.modalWindow.find('[data-use=closemodal]');
		this.init()
	}

	init() {
		let __self = this;
		this.modalTriggers.each(function (i, modalTrigger) {
			$(modalTrigger).on('click', function (e) {
				e.preventDefault();
				let currentModalName = $(this).data('modal');
				if (currentModalName === 'review') {
                    if (document.readyState == 'loading') {
                        window.addEventListener('load', function() {
                            try {
                                yaCounter42842649.reachGoal('SHOW_REVIEW_FORM');
                            } catch (e) {
                                Raven.captureException(e);
                            }
                        });
                    } else {
                        try {
                            yaCounter42842649.reachGoal('SHOW_REVIEW_FORM');
                        } catch (e) {
                            Raven.captureException(e);
                        }
                    }
                }
				let currentTrigger = $(this);
				__self.showModal(currentModalName, currentTrigger);
			})
		});

		this.closeModal.on('click', function (e) {
			let modal = $(this).parents('#modal_wrap').data('modalname');
			__self.hideModal(modal);
		});


		this.modalWindow.on('click', function (e) {
			let container = $(this).find('.modal_inner');

			if (!container.is(e.target) && container.has(e.target).length === 0) {
				let modal = $(this).data('modalname');
				__self.hideModal(modal);
			}
		});

		$( document ).on( 'keydown', function ( e ) {
			if ( e.keyCode === 27 ) { // ESC
				let modalName = __self.modalDataName;
				__self.hideModal(modalName)
			}
		});
	}

	showModal(modalName, modalTrigger) {
		let __self = this;
		if (!this.openStatus) {
			$('html').addClass('modalled');
			if (this.callBeforeOpen && typeof this.callBeforeOpen === typeof(Function)) this.callBeforeOpen(modalName, modalTrigger);
			let modal = $('[data-modalname=' + modalName + ']');
			modal.removeClass('hidden').addClass(this.animationOpen).one(this.animationEnd, function (e) {
				e.stopPropagation();
				$(this).removeClass(__self.animationOpen);
				__self.openStatus = true;
				if (__self.callAfterOpen && typeof __self.callAfterOpen === typeof(Function)) __self.callAfterOpen(modalName, modalTrigger);
			});
		}
	}

	hideModal(modalName) {
		let __self = this;
		if (this.openStatus) {
			$('html').removeClass('modalled');
			let modal = $('[data-modalname=' + modalName + ']');
			modal.addClass(this.animationClose).one(this.animationEnd, function (e) {
				e.stopPropagation();
				$(this).removeClass(__self.animationClose);
				$(this).addClass('hidden');
				__self.openStatus = false
			});
			if (this.callAfterClose && typeof this.callAfterClose === typeof(Function)) this.callAfterClose(modalName);
		}
	}


}

module.exports = Modal;
},{}],18:[function(require,module,exports){
module.exports = function (el, btn) {

	const modal = $('[data-modalname="' + el + '"]');
	const serviceName = btn.data('title');
	const servicePhone = btn.data('phone');
	const serviceTitleSeo = btn.data('titleseo');

	const objSend = {};

	var phoneInputMask = {
		clearIfNotMatch: true,
		translation: {
			'0': {pattern: /\d/},
			'9': ''
		}
	};

	$('#ask_phone').mask("+7 (000) 000-0000", phoneInputMask);

	modal.find('span#service_name').text(serviceName);

    modal.find('.phone_link').attr("href", "tel:"+servicePhone);
    modal.find('.phone_link').text(servicePhone);
    modal.find('#ask_hidden_service_seo').val(serviceTitleSeo);
    modal.find('#ask_hidden_service').val(serviceName);


	$('#ask_call').one('click', function (event) {
		event.stopPropagation();
		$('.modal_wrap').addClass('step_2_active');
		$('.question_form').addClass('slideInUp').one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function (event) {
			event.stopPropagation();
			$(this).removeClass('slideInUp')
		})
	});


	$(".question_form").validate({

		highlight: function(element, errorClass) {
			$(element).parent('.field').addClass('error');
		},

		unhighlight: function(element, errorClass, validClass) {
			$(element).parent('.field').removeClass('error');
		},

		/*****************************************************************

		 Тэг для обрамления сообщения об ошибке

		 *****************************************************************/

		errorElement: "i",

		/*****************************************************************

		 В объекте "rules", для каждого поля устанавливаем правило.

		 *****************************************************************/
		rules: {
			name: {
				required: true,
				minlength: 2
			},
			phone: {
				required: true
			},
			question: {
				required: true
			}
		},
		/*****************************************************************

		 В объекте "messages", для каждого поля устанавливаем необходимое сообщение.

		 *****************************************************************/
		messages: {
			name: {
				required: "Обязательное для заполнение поле",
				minlength: "Пожалуйста, минимум 5 символов"
			},
			phone: {
				required: "Обязательное для заполнение поле"
			},
			question: {
				required: "Обязательное для заполнение поле"
			}
		},
		/*****************************************************************

		 Объект "submitHandler" является методом-управляющим отправкой формы,
		 при помощи его мы сохраняем данные полей второго шага в соответствующие
		 поля нашего объекта "objSend", который мы инициировали вначале. Затем
		 выводим объект в консоль, для того чтоб проверить записались ли значения
		 в свойства "objSend". После этого, мы переходим ко третьему шагу формы, и
		 отменяем "отсылку" формы.

		 *****************************************************************/

		submitHandler: function (form) {
			objSend.name = $('#ask_name').val();
			objSend.phone = $('#ask_phone').val();
			objSend.question = $('#ask_question').val();
            objSend.company = $("#ask_hidden_service").val();
			objSend.companySeo = $("#ask_hidden_service_seo").val();
			objSend.currentURL = window.location.href;
            //objSend.currentState = JSON.stringify(initialStateSerialization);
            objSend._token = $("#ask_hidden_token").val();

			$('.modal_wrap').addClass('step_3_active');
			$('.thanks').addClass('slideInUp').one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function (event) {
				event.stopPropagation();
				$(this).removeClass('slideInUp')

				modal.find('#modal_ask_service').text(serviceName)
				modal.find('#modal_ask_phone').text(objSend.phone)
                modal.find('#modal_ask_name').text(objSend.name)

			});

			/*****************************************************************

			 Передаем данные AJAX-ом

			 *****************************************************************/

            let successFn = function (data, textStatus, jqXHR) {
                //alert('Спасибо за заявку');
            };
            $.post('/lead/send', objSend, successFn);

            event.preventDefault();
			return false;
		}
	});


};
},{}],19:[function(require,module,exports){
module.exports = function () {
  if (currentPage !== 'profile' || !window.ymaps) {
    return;
  }
  let myModalMap,
    myPlacemark;

  window.ymaps.ready(init2);
  function init2() {
    const createMap = function (params) {
      myModalMap = new ymaps.Map('modalmap', {
        center: [params.coordinates[1], params.coordinates[0]],
        zoom: 10,
        controls: ['zoomControl'],

      }, {
        searchControlProvider: 'yandex#search',
      });
      myPlacemark = new ymaps.Placemark([params.coordinates[1], params.coordinates[0]], {}, {
        preset: 'islands#blueDotIconWithCaption',
      });
      myModalMap.geoObjects.add(myPlacemark);
    };
    const getParams = function () {
      const dataContainer = $('.profile-ya-map');
      return dataContainer.data('services');
    };
    const params = getParams();
    createMap(params);
  }
};

},{}],20:[function(require,module,exports){
module.exports = function () {

///////////////////////// ADD WORK FIELD /////////////////////////////////////////////

	const addWorkBtn = $('#add_work_price');
	const addWorkBtnWrap = addWorkBtn.parent('.field');
	const row = $('.work_price_row').clone();
	const str = row.removeClass('work_price_row').addClass('for_delete').prepend(`<button type="button" class="delete_work">
                      <svg class="for_delete_icn">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/img/project_icons.svg#close"></use>
                      </svg>
                    </button>`);
	str.find('label.form_label').remove();
	str.find('select').removeAttr('id').removeAttr('name').removeAttr('aria-invalid');
	str.find('input').removeAttr('id').removeAttr('name').removeAttr('aria-invalid');
	addWorkBtn.on('click', function () {
		addWorkBtnWrap.before(str.clone());
	});
	$(document).on('click', '.delete_work', function () {
		$(this).parent('.row').remove();
	});

///////////////////////// COUNT SYMBOLS TYPE /////////////////////////////////////////////

	function symbolCount(el, maxSize, display) {
		const field = $(el);
		const displayCount = $(display);
		const max = maxSize;
		field.on('change keyup paste input', function (evt) {

			var code = (evt.keyCode || evt.which);
			var val = $(this).val();
			var len = $(this).val().length;

			if (code == 37 || code == 38 || code == 39 || code == 40) {
				return;
			}

			if (len <= maxSize) {
				field.val(val);
				displayCount.text(len);
			} else {
				field.val(val.substring(0, max));
			}


		});
	}


	symbolCount('#review_head', 150, '#display_head');
	symbolCount('#review_text', 350, '#display_text');

//////////////////////////// REVEAL IMAGE BLOCK //////////////////////////////////////////

	$('#add_image_block').on('click', function (evt) {
		$(this).parent('.field').prev('.review_images').slideToggle('fast');
		$(this).hide();
	});

//////////////////////////// ADD PHOTO REVIEW MODULE //////////////////////////////////////////

	$('.review_img_square').on('click', function (e) {
		e.stopPropagation();
		if ($(this).parent('.review_img_wrap').hasClass('checked')) {
			$(this).parent('.review_img_wrap').removeClass('checked');
			$(this).find('img').removeClass('visible');
		} else {
			$(this).next('input[type=file]').click();
		}


	});

	$('.review_images').find('input[type=file]').each(function (i, fileInput) {
		$(fileInput).on('change', function (e) {
			for (var i = 0; i < e.originalEvent.srcElement.files.length; i++) {

				var file = e.originalEvent.srcElement.files[i];

				var reader = new FileReader();

				reader.onloadend = ()=> {
					// console.log(file.type)
					if (file.type == 'image/jpeg' || file.type == 'image/png') {
						$(this).parent('.review_img_wrap').find('img.review_img').attr('src', reader.result).addClass('visible');
						$(this).parent('.review_img_wrap').addClass('checked');
					} else {
						$('.review_photo_extensions').css('color', '#F2352D')
						return false;
					}
				};

				reader.readAsDataURL(file);

				$(this).val('')


			}

		})
	});

	//////////////////////////// FORM SUBMIT AND STEP 2 //////////////////////////////////////////

	$('#review_work, #review_auto').on('change', function() {
		$(this).valid();
	});


	$(".review_form").validate({

		ignore: [],

		highlight: function (element, errorClass) {
			$(element).parent('.field').addClass('error');

			if($(element).is('#reviev_stars_count')){
				$(element).next('.review_stars_wrap').addClass('error');
			}
		},

		unhighlight: function (element, errorClass, validClass) {
			$(element).parent('.field').removeClass('error');

			if($(element).is('#reviev_stars_count')){
				$(element).next('.review_stars_wrap').removeClass('error');
			}
		},


		rules: {
			review_stars: {
				required: true,
				min: 1
			},
			review_name: {
				required: true
			},
			review_mail: {
				required: true,
				email: true
			},
			review_auto: {
				required: true
			},
			review_head: {
				required: true
			},
			review_text: {
				required: true
			},
			review_work: {
				required: true
			}
		},
		errorPlacement: $.noop,


		submitHandler: function (form) {
			if ($(".review_form").valid()) {
                if (document.readyState == 'loading') {
                    window.addEventListener('load', function() {
                        try {
                            yaCounter42842649.reachGoal('SENT_REVIEW');
                        } catch (e) {
                            Raven.captureException(e);
                        }
                    });
                } else {
                    try {
                        yaCounter42842649.reachGoal('SENT_REVIEW');
                    } catch (e) {
                        Raven.captureException(e);
                    }
                }
                let cases = [];
                $('.review_case_row').each(function () {
                    cases.push({
                        serviceTitleSeo : $(this).find('.work_field select').val(),
                        price : $(this).find('.price_field input').val()
                    });
                });
                let reviewObject = {
                    rating: $('#reviev_stars_count').val(),
                    username: $('#review_name').val(),
                    email: $('#review_mail').val(),
                    carTitleSeo: $('#review_auto').val(),
                    commentTitle: $('#review_head').val(),
                    comment: $('#review_text').val(),
                    cases : cases,
                    companyTitleSeo : initialState.currentCompany
                };

                $.ajax({
                    url: '/review/send',
                    type: 'post',
                    data: reviewObject,
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    dataType: 'json',
                    success: function (data) {
                        $('.review_step_1').slideUp(200);
                        $('.review_step_2').slideDown(200);
                    }
                });
			}
		}
	});


};
},{}],21:[function(require,module,exports){
const $partnerContactForm = $('#partners_form');
const $partnerNameInput = $('#partner_name');
const $partnerPhoneInput = $('#partner_phone');
const $partnerModal = $('#partners_modal');
const $partnerModalClose = $('#partners_modal_close');

if ($partnerContactForm && $partnerModal && $partnerModalClose) {
  const SHOW_PARTNER_MODAL_CLASS = 'partners_modal_active';

  $partnerModalClose.on('click', () => {
    $partnerModal.removeClass(SHOW_PARTNER_MODAL_CLASS);
  });

  $(document).keydown((event) => {
    if (event.keyCode === 27 && $partnerModal.hasClass('partners_modal_active')) {
      $partnerModal.removeClass(SHOW_PARTNER_MODAL_CLASS);
    }
  });

  $partnerContactForm.submit((event) => {
    event.preventDefault();

    const url = $partnerContactForm.attr('action');
    const name = $partnerNameInput.val();
    const phone = $partnerPhoneInput.val();

    $.ajax({
      method: 'POST',
      url,
      data: {
        name,
        phone,
        session_id
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      success() {
        $partnerModal.addClass('partners_modal_active');
        $partnerNameInput.val('');
        $partnerPhoneInput.val('');
      },
    });
  });
}

},{}],22:[function(require,module,exports){
module.exports = function () {
	const link = $('#profile_rating_comment_show_more_link');

	if (currentPage == 'profile') {
        initialState.reviewsCount = parseInt(initialState.reviewsCount);
		initialState.reviewsNextPageStart = parseInt(initialState.reviewsNextPageStart);
        var paginationStep = 10;
        var reviewsLeft = initialState.reviewsCount - initialState.reviewsNextPageStart;
	}

	link.on('click', function (evt) {
		evt.preventDefault();
		var start = initialState.reviewsNextPageStart,
			end = start+paginationStep;
        initialState.reviewsNextPageStart = end;

		$.ajax({
			dataType: "html",
			url: `/rest/review?companyTitleSeo=${initialState.currentCompany}&_sort=_createdAt&_order=DESC&_start=${start}&_end=${end}`,
			success: function (data) {
				// console.log(data);
				$('.profile_rating_comments_content_comment_list').append(data);
				if (end >= initialState.reviewsCount) {
                    link.remove();
                }
                reviewsLeft = reviewsLeft - 10;
                if (reviewsLeft > 10) {
                    link.text(`Загрузить еще 10 отзывов из ${reviewsLeft}`);
				} else {
                    link.text(`Загрузить еще ${reviewsLeft} отзывов из ${reviewsLeft}`);
				}

                $("[data-type='read_more_text']").on('click', function(event){
                    $(this).hide();
                    $(this).prev("[data-type='more_sign']").hide();
                    $(this).next("[data-type='secondary_text']").show();
                });
			}
		});
	});
};
},{}],23:[function(require,module,exports){
// class ReadMore {
// 	constructor(selector, maxTextLength){
// 		this.block = selector;
// 		this.maxTextLength = maxTextLength;
// 		this.fullTextHeight = this.block.outerHeight();
// 		this.moretext = "<a href='#' id='readMore'>... раскрыть</a>";
// 		this.fullContent = this.block.html();
// 		this.smallContent = this.fullContent.substr(0, this.maxTextLength);
// 		this.init()
// 	}
// 	init(){
//
// 		let __self = this;
// 		this.block.html(this.smallContent + this.moretext).css({
// 			height: this.block.outerHeight()
// 		});
//
// 			$('#readMore').on('click', function(e){
// 				e.preventDefault();
// 				__self.block.html(__self.fullContent).animate({
// 					height: __self.fullTextHeight
// 				}, 400, function(){
// 					__self.block.css({
// 						height : 'auto'
// 					})
// 				});
// 			})
// 	}
// }
//
// if($('#profile_info').length){
// 	const profileInfo = new ReadMore( $('#profile_info'), 200 );
// }
//

$("[data-type='read_more_text']").on('click', function(event){
	$(this).hide();
	$(this).prev("[data-type='more_sign']").hide();
	$(this).next("[data-type='secondary_text']").show();
});
},{}],24:[function(require,module,exports){
let createMap;
let myMap;
let currentOpenedMapId = null;
let currentGroupId = null;
module.exports = function () {
  if (currentPage !== 'feed' || !window.ymaps) {
    return;
  }

  // Как только будет загружен API и готов DOM, выполняем инициализацию
  ymaps.ready(init);

  // Инициализация и уничтожение карты при нажатии на кнопку.
  function init() {
    createMap = function (mapId, params) {
      currentOpenedMapId = mapId;
      myMap = new ymaps.Map(mapId, {
        center: [params[0].point.coordinates[1], params[0].point.coordinates[0]], // Новосибирск
        zoom: 12,
        controls: ['zoomControl'],
      }, {
        searchControlProvider: 'yandex#search',
      });
      myMap.behaviors.disable('scrollZoom');
      $('.ymaps-2-1-60-map-copyrights-promo').remove();

      const myGroup = new ymaps.GeoObjectCollection({});
      for (var i = params.length - 1; i >= 0; i--) {
        let preset = 'islands#blueDotIcon';
        if (i == 0) {
          preset = 'islands#redDotIcon';
        }
        var host = window.location.host,
          link = `/company/${params[i].titleSeo}`,
          coordinates = [params[i].point.coordinates[1], params[i].point.coordinates[0]],
          starRating = `r${Math.round(params[i].rating)}`,
          actualStatus = '',
          isOpenNow = function () {
            if (params[i].isOpenNow) {
              actualStatus = 'Открыто';
            } else {
              actualStatus = 'Закрыто';
            }
          };
        isOpenNow();
        myGroup.add(new ymaps.Placemark(coordinates, {
          balloonContentBody: [
            '<address class="b-balloon-map">',
            `<a class="b-balloon-map__header" href=${link}>` +
                        `<span class="as_name_name">${params[i].title}</span>`,
            `${'   <span class="as_name_district"> — ' +
                        '   <span>'}${params[i].address}</span>` +
                        '</span>' +
                        '</a>',
            `${'<div class="as_work_status">' +
                        '   <span>' +
                        '       <span class="as_work_status__actual-status">'}${actualStatus}</span>` +
                        `       <span>${params[i].todayWorkingTimeInfo}</span>` +
                        '   </span>' +
                        '</div>',
            '</br>',
            `${'<div class="starts_rating_block">' +
                        '<div class="as_stars_rating '}${starRating}">` +
                        '   <svg class="rating_icon">' +
                        '       <use href="/img/project_icons.svg#star">' +
                        '   </svg>' +
                        '   <svg class="rating_icon">' +
                        '       <use href="/img/project_icons.svg#star">' +
                        '   </svg>' +
                        '   <svg class="rating_icon">' +
                        '       <use href="/img/project_icons.svg#star">' +
                        '   </svg>' +
                        '   <svg class="rating_icon">' +
                        '       <use href="/img/project_icons.svg#star">' +
                        '   </svg>' +
                        '   <svg class="rating_icon">' +
                        '       <use href="/img/project_icons.svg#star">' +
                        '   </svg>' +
                        '</div>' +
                        `<span class="rating_count">${params[i].reviewsCount}</span>` +
                        '</div>',
            `<div class="features-text">${params[i].featuresText}</div>`,
            '</address>',

          ].join(''),
        }, {
          preset,
        }));
      }
      const resizeToNewBounds = function () {
        // костыль
        setTimeout(() => {
          myMap.setBounds(myGroup.getBounds(), { checkZoomRange: true });
        }, 500);
      };

      // myGroup.events.add('optionschange', resizeToNewBounds);
      myMap.geoObjects.events.add('add', resizeToNewBounds);
      myMap.geoObjects.add(myGroup);
      $(`#${mapId}`).show();
    };
  }
};

const getParamsByGroupId = function (groupId) {
  const dataContainer = $(`.result_feed_group[data-company-group-id='${groupId}']`);
  return dataContainer.data('services');
};

$(document).on('click', '.feed_group_button_show_more_wrapper', (evt) => {
  evt.preventDefault();
  const companyGroupId = $(evt.currentTarget).data('company-group-id');
  const mapId = `feed_group_map_${companyGroupId}`;

  if (!myMap) {
    var params = getParamsByGroupId(companyGroupId);
    createMap(mapId, params);
    // $(evt.currentTarget).text('Скрыть карту');
  } else {
    myMap.destroy();// Деструктор карты
    myMap = null;
    $(`#${currentOpenedMapId}`).hide();
    if (mapId !== currentOpenedMapId) {
      // $('.feed_group_button_show_more_wrapper[data-company-group-id="' + currentGroupId + '"]').text('Показать карту снова')
      currentGroupId = companyGroupId;
      var params = getParamsByGroupId(companyGroupId);

      createMap(mapId, params);
      // $(evt.currentTarget).text('Скрыть карту');
    } else {
      // $(evt.currentTarget).text('Показать карту снова');
    }
  }
});

},{}],25:[function(require,module,exports){
module.exports = function () {

	$('#review_auto').on('change', function() {
		$(this).valid();
	});

	$('.review_stars_wrap').on('click',function(evt){
		$(this).prev('.reviev_stars_count').valid();
	});

	$("#profile_rating_comments_form").validate({

		ignore: [], // ignore hidden elements
		errorPlacement: $.noop, // no text errors

		highlight: function (element, errorClass) {

			$(element).parent('.field').addClass('error');

			if ($(element).attr("name") === "review_stars") {
				$(element).next('.review_stars_wrap').addClass('error');
			}
		},

		unhighlight: function (element, errorClass, validClass) {
			$(element).parent('.field').removeClass('error');

			if ($(element).attr("name") === "review_stars") {
				$(element).next('.review_stars_wrap').removeClass('error');
			}
		},

		rules: {
			review_stars: {
				required: true,
				min: 1
			},
			review_comment: {
				required: true,
			},

			review_name: {
				required: true,
			},

			review_mail: {
				required: true,
				email: true
			},
			review_auto: {
				required: true,
			}
		},


		submitHandler: function (form) {
			if ($("#profile_rating_comments_form").valid() ) {


                let reviewObject = {
					// Company exact name
                    companyTitleSeo : initialState.currentCompany,

                    // The Same
                    rating: $('.review_stars_wrap').data('stars'),
                    comment: $('.review_form_comment').val(),
                    commentPlus: $('.review_form_plus').val(),
                    commentMinus: $('.review_form_minus').val(),
                    carTitleSeo: $('.review_form_carbrand').val(),
                    serviceTitleSeo: $('.review_form_service').val(),
                    servicePrice: $('.review_form_service_price').val(),
                    username: $('.review_form_username').val(),
                    email: $('.review_form_email').val(),

                };

                $.ajax({
                    url: '/review/sendFromProfile',
                    type: 'post',
                    data: reviewObject,
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    dataType: 'json',
                    success: function (data) {
	                    $("#profile_rating_comments_form").slideUp();
	                    $('.profile_rating_comments_form_thanks').slideDown();
                    }
                });
                if (document.readyState == 'loading') {
                    window.addEventListener('load', function() {
                        try {
                            yaCounter42842649.reachGoal('SENT_REVIEW');
                        } catch (e) {
                            Raven.captureException(e);
                        }
                    });
                } else {
                    try {
                        yaCounter42842649.reachGoal('SENT_REVIEW');
                    } catch (e) {
                        Raven.captureException(e);
                    }
                }
			}
			return false;
		}
	});
};
},{}],26:[function(require,module,exports){
module.exports = function () {

	var allCommentsReviviews = $('ul.profile_rating_comment_images_list');

	allCommentsReviviews.each(function (index, list) {
		var listIndex = index;

		$(list).find('.profile_rating_comment_images_item').each(function(indexItem, item){
			$(item).on('click',function(evt){
				// console.log('listIndex == ', listIndex);
				// console.log('itemIndex == ', indexItem);
				var pswpElement = document.querySelectorAll('.pswp')[0];
				var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, reviewItems[listIndex], {index: indexItem});
				gallery.init();
			});
		});

	});


	// $('li.profile_rating_comment_images_item').on('click',function(e){
	// 	// // alert()
	// 	// // e.preventDefault();
	// 	// var index = $('.profile_rating_comment_images_list').find('.profile_rating_comment_images_item').index( this );
	// 	// console.log(index)
	// 	// var pswpElement = document.querySelectorAll('.pswp')[0];
	// 	// var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, reviewItems, {index: index});
	// 	// gallery.init();
	// });


};
},{}],27:[function(require,module,exports){
module.exports = function () {
	
	var review_page_form_service = $('#review_page_form_service');
	review_page_form_service.chosen({
		no_results_text: ":( нет такой услуги",
		inherit_select_classes: true,
		search_contains: true,
		// include_group_label_in_selected : true
	});
	
	$('#review_form_block_reset_all_checkboxes').on('click', function (evt) {
		evt.preventDefault();
		$('.review_form_block_checkbox_column').find('input[type=checkbox]').prop('checked', false)
	});
	
	// Popup
	/////////////
	$('#review_form_block_review_example').on('click', function (evt) {
		$('.review_form_block_review_popup').toggle()
	});
	$('.review_form_block_review_popup_close_btn').on('click', function (evt) {
		$('.review_form_block_review_popup').hide();
	});
	
	
	$('.review_page_form').garlic({
		excluded: '.chosen-search-input'
	});
	
	
	if ($.fn.intlTelInput) {
		$("#phone").intlTelInput({
			onlyCountries: ["ru", "md", "ua", "az", "am", "by", "kz", "kg", "tj", "tm", "uz"],
			initialCountry: "auto",
			geoIpLookup: function (callback) {
				$.get('https://ipinfo.io', function () {
				}, "jsonp").always(function (resp) {
					var countryCode = (resp && resp.country) ? resp.country : "";
					callback(countryCode);
				});
			},
			nationalMode: false
		});
		var countryData = $.fn.intlTelInput.getCountryData();
		$.each(countryData, function (i, country) {
			country.name = country.name.replace(/.+\((.+)\)/, "$1");
		});
	}
	
	
	$('#review_page_auto, #review_page_city').on('change', function () {
		$(this).valid();
	});
	
	
	$(".review_page_form").validate({
		
		ignore: [],
		
		highlight: function (element, errorClass) {
			$(element).parent('.field').addClass('error');
			
			// if ($(element).is('.reviev_stars_count')) {
			//     $(element).next('.review_stars_wrap').addClass('error');
			//     $(element).parent('.reviev_stars_line_block').addClass('error');
			//     $(element).parent('.reviev_stars_line_block').prev('label').addClass('error_label');
			// }
		},
		
		unhighlight: function (element, errorClass, validClass) {
			$(element).parent('.field').removeClass('error');
			
			// if ($(element).is('.reviev_stars_count')) {
			//     $(element).next('.review_stars_wrap').removeClass('error');
			//     $(element).parent('.reviev_stars_line_block').removeClass('error');
			//     $(element).parent('.reviev_stars_line_block').prev('label').removeClass('error_label');
			// }
		},
		
		
		rules: {
			
			review_page_service_name: {
				required: true
			},
			// review_page_city: {
			//     required: false,
			//     min: 1
			// },
			review_page_address: {
				required: true
			},
			review_form_site: {
				required: true
			},
			review_page_comment: {
				required: true,
				minlength: 100
			},
			review_page_auto: {
				required: true
			},
			
			// review_page_personal: {
			//     required: true,
			//     min: 1
			// },
			
			// review_page_price: {
			//     required: true,
			//     min: 1
			// },
			// review_page_comfort: {
			//     required: true,
			//     min: 1
			// },
			// review_page_speed: {
			//     required: true,
			//     min: 1
			// },
			review_page_username: {
				required: true
			},
			
			review_page_user_email: {
				required: true,
				email: true
			}
			
		},
		errorPlacement: $.noop,
		
		
		submitHandler: function (form) {
			if ($(".review_page_form").valid()) {

                var serviceRecomended = new Array();
                var serviceCheckboxes = $(".review_form_block_checkbox_column input:checkbox");
                serviceCheckboxes.each(function (event) {
                    var valueName = $(this).attr('data-service');
                    if ($(this).is(':checked')) {
                        serviceRecomended.push(valueName);
                    }
                });

                var services = new Array();
                $('.search-choice-close').each(function () {
                    var currentId = $(this).attr('data-option-array-index');
                    var titleSeo = $('.review_page_form_service .simple_chosen_element_index:eq('+currentId+')').val();
                    services.push(titleSeo);
                });

                let reviewObject = {
                    services: services,
                    serviceRecomended: serviceRecomended,

					comment: $('.review_form_comment').val(),
					carTitleSeo: $('.review_form_carbrand').val(),
					
					// change on the back end
					ratingPersonal: $('#review_form_personal_block').find('input').val(),
					ratingPrice: $('#review_form_price_block').find('input').val(),
					ratingComfort: $('#review_form_comfort_block').find('input').val(),
					ratingSpeed: $('#review_form_speed_block').find('input').val(),
					price: $('.review_form_service_price').val(),
					priceCurrency: $('.review_currency').text(),
					//commentPlus: $('.review_form_plus').val(),
					//commentMinus: $('.review_form_minus').val(),
					
					
					//serviceTitleSeo: $('.review_form_service').val(),
					
					username: $('.review_form_username').val(),
					email: $('.review_form_email').val(),
					
					// companyTitleSeo : initialState.currentCompany
				};

                if (currentPage == 'review') {
                	reviewObject.companyName = $('.review_form_company_name').val();
                    reviewObject.address= $('.review_form_address').val();
					reviewObject.phone= $('.review_form_phone').val();
					reviewObject.site= $('.review_form_site').val();
                } else {
                	reviewObject.companyTitleSeo = initialState.currentCompany;
				}
				
				$.ajax({
					url: '/review/sendFromPage',
					type: 'post',
					data: reviewObject,
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					dataType: 'json',
					success: function (data) {
						$('.review_page_form').hide();
						$('.review_form_thank').show();
						let comment = "Отзыв будет опубликован после проверки модератором (#" + data.reviewId + ").";
						$('.review_form_thank_body').text(comment);
						$('.form_control').garlic('destroy');
						$('html, body').animate({scrollTop: 0}, 'slow');
					}
				});
				if (document.readyState == 'loading') {
                    window.addEventListener('load', function() {
                        try {
                            yaCounter42842649.reachGoal('SENT_REVIEW');
                        } catch (e) {
                            Raven.captureException(e);
                        }
                    });
				} else {
                    try {
                        yaCounter42842649.reachGoal('SENT_REVIEW');
                    } catch (e) {
                        Raven.captureException(e);
                    }
				}
			}
		}
	});
	
	$('.review_form_address').keypress(function (e) {
		if (e.which === 13) {
			return false;
		}
	});
	
	$('#reload_review_form').on('click', () => {
		location.reload()
	});
	
	
	$('.currency_dropdown ul li a').on('click', function (evt) {
		evt.preventDefault();
		let currency = $(this).text();
		$('#currency_button .review_currency').text(currency).click();
	});
	
	
	// Rating Binding Count
	
	$('document').ready(function () {
		let count = $('#review_form_block_results_rating');
		let arrRatings = $('input.reviev_stars_count');
		getAllFiledsValue(arrRatings);
		arrRatings.each(function (index, input) {
			$(input).on('changedValue', function () {
				getAllFiledsValue(arrRatings)
			});
		});
	});
	
	
	function getAllFiledsValue(arrFields) {
		let init = 0;
		arrFields.each(function (i, v) {
			init += parseInt($(v).val());
		});
		$('#review_form_block_results_rating').text(  Math.round (init/arrFields.length * 10) / 10 );
		// console.log(init/arrFields.length)
	}
	
	
};
},{}],28:[function(require,module,exports){
const MARK_CATEGORY = 'Найдено в марках';
const COMPANY_CATEGORY = 'Найдено в автосервисах';
const SERVICE_CATEGORY = 'Найдено в услугах';

const searchConfig = {
  serviceUrl: '/search',
  paramName: 'q',
  params: { city: initialState.citySeo },
  dataType: 'json',
  groupBy: 'category',
  showNoSuggestionNotice: true,
  noSuggestionNotice: 'По запросу ничего не найдено',
  onSelect(suggestion) {
    switch (suggestion.data.category) {
      case COMPANY_CATEGORY:
        initialState.currentCompany = suggestion.data.seo;
        window.autoo.reloadPage('company');
        break;
      case SERVICE_CATEGORY:
        initialState.titleSeo = suggestion.data.seo;
        window.autoo.reloadPage('feed');
        break;
      case MARK_CATEGORY:
        initialState.carBrandSeo = suggestion.data.seo;
        initialState.titleSeo = 'all';
        window.autoo.reloadPage('feed');
        break;
      default:
        initialState.titleSeo = 'all';
        window.autoo.reloadPage('feed');
    }
  },
  transformResult({ cars, services, companies }) {
    function mapForAutocomplete(inputArray, category, extraFactory) {
      if (inputArray) {
        return inputArray.map(result => ({
          value: result.title,
          data: {
            category,
            seo: result.titleSeo,
            extra: extraFactory ? extraFactory(result) : '',
          },
        }));
      }
      return [];
    }

    const companiesExtraFactory = ({ districts }) => ((districts && districts.length) ? `${districts.join(', ')}` : '');
    const servicesExtraFactory = ({ parentService }) => ((parentService && parentService.affixes && parentService.affixes['Где']) ? parentService.affixes['Где'] : '');

    const carsResult = mapForAutocomplete(cars, MARK_CATEGORY);
    const companiesResult = mapForAutocomplete(companies, COMPANY_CATEGORY, companiesExtraFactory);
    const servicesResult = mapForAutocomplete(services, SERVICE_CATEGORY, servicesExtraFactory);

    return {
      suggestions: [...carsResult, ...companiesResult, ...servicesResult],
    };
  },
  onSearchComplete(query, suggestions) {
    const $dropdown = $('.autocomplete-suggestions:visible');
    const fixedDropdownPositionPages = ['feed', 'profile', 'categoriesGeo'];
    if (fixedDropdownPositionPages.includes(window.currentPage)) {
      $dropdown.addClass('dropdownFixedPosition');
    }
    $.each($dropdown.children('.autocomplete-suggestion'), (key, el) => {
      const $el = $(el);
      const { data } = suggestions[$el.attr('data-index')];
      if (data) {
        const { extra } = data;
        $el.append(`<span class="autocomplete-suggestion_hint"> ${extra}</span>`);
      }
    });
  },
};

const searchCityConfig = {
  serviceUrl: '/autocompletecity',
  lookupFilter(suggestion, originalQuery, queryLowerCase) {
    const re = new RegExp(`\\b${$.Autocomplete.utils.escapeRegExChars(queryLowerCase)}`, 'gi');
    return re.test(suggestion.value);
  },
  onSelect(suggestion) {
    initialState.citySeo = suggestion.data;
    window.autoo.reloadPage(window.currentPage);
  },
};

function initAutocomplete($el, config) {
  $el.keydown((event) => {
    if (event.which === 13 || event.keyCode === 13) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  });
  $el.autocomplete(config);
}

initAutocomplete($('.city_input'), searchCityConfig);
initAutocomplete($('#main_search'), searchConfig);
initAutocomplete($('.header_form_search'), searchConfig);
initAutocomplete($('#autocomplete_header_form'), searchConfig);

},{}],29:[function(require,module,exports){
function scrollBlockToTop(block, offset, speed) {
	$('html, body').animate({
		scrollTop: block.offset().top - (offset || 0)
	}, (speed || 'slow'), function(){
		$(document).on('scroll', findCurrentLink);
	});
}


$(document).ready(function () {
	let links = $('.category_list').find('a');
	links.each(function (i, link) {
		$(link).on('click', function (evt) {
			evt.preventDefault();
			$(document).off("scroll");
			links.each(function () {
				$(this).removeClass('active');
			});
			$(this).addClass('active');
			let id = $(this).attr('href').slice(1);
			let block = $('a#' + id).parent('.category_block');
			scrollBlockToTop(block, 80, 400);
		});
	});
});
$(document).on('scroll', findCurrentLink);

function findCurrentLink() {
	var fromTop = $(document).scrollTop(),
		blocks = $('.category_block'),
		links = $('.category_list').find('a');
	blocks.each(function () {
		
		if ($(this).offset().top <= fromTop + 180) {
			
			let currentId = $(this).find('.anchor_link').attr('id');
			blocks.each(function () {
				$(this).removeClass('active');
			});
			links.each(function () {
				$(this).removeClass('active');
				if ($(this).attr('href') === '#' + currentId) $(this).addClass('active');
			});
		}
	});
}
},{}],30:[function(require,module,exports){
//////////////////////////// SET RATING STARS //////////////////////////////////////////

module.exports = function (ratingBlock) {
	
	const rBlock = $(ratingBlock);
	const startRatingState = rBlock.attr('data-startRating') ? rBlock.attr('data-startRating') : null;
	// console.log(startRatingState);
	const reviewStarsWrap = rBlock.find('.review_stars_wrap');
	const starsLine = rBlock.find('.stars_line'); // Block we hover-mouseover
	const markedStars = rBlock.find('.marked_stars_line'); // Block we change width
	const starInput = rBlock.find('.reviev_stars_count'); // Input we change value
	
	// Calculations
	const starWidth = rBlock.find('svg').outerWidth(); // 24
	// const totalStars = starsLine.find('svg').length; // 5
	// const starsContainerWidth = starsLine.outerWidth(); // 120
	const starsOffset = starsLine.offset();
	
	
	highLightStarsRating(startRatingState * starWidth);
	
	
	function highLightStarsRating(starsNum) {
		let highLightStars = starsNum / starWidth;
		markedStars.css('width', starsNum);
		starInput.val(highLightStars);
	}
	
	reviewStarsWrap.on('mousemove', function (evt) {
		
		let starsLinePos = {
			left: evt.pageX - starsOffset.left,
			top: evt.pageY - starsOffset.top
		};
		
		
			let stars = (Math.ceil(starsLinePos.left / starWidth)) * starWidth;
			highLightStarsRating(stars);
		
	});
	
	reviewStarsWrap.on('mouseleave', function (evt) {
		
		let dataStars = reviewStarsWrap.attr('data-stars');
		
		if (dataStars) {
			highLightStarsRating(dataStars * starWidth)
		} else {
			highLightStarsRating(0)
		}
		if (rBlock.attr('data-startRating')) {
			highLightStarsRating(startRatingState * starWidth);
		}
		
	});
	
	reviewStarsWrap.on('click', function (evt) {
		rBlock.removeAttr('data-startRating');
		let stars = markedStars.outerWidth();
		reviewStarsWrap.attr('data-stars', stars / starWidth);
		highLightStarsRating(stars);
		starInput.trigger('changedValue');
	})
	
};


},{}],31:[function(require,module,exports){
module.exports = function(){

	if (/MSIE 10/i.test(navigator.userAgent)) {
		// This is internet explorer 10
		// window.alert('isIE10');
		addScriptToPage();
	}

	if (/MSIE 11/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
		// This is internet explorer 9 or 11
		// window.location = 'pages/core/ie.htm';
		// window.alert('isIE11');
		addScriptToPage();
	}

	if (/Edge\/\d./i.test(navigator.userAgent)){
		// This is Microsoft Edge
		// window.alert('Microsoft Edge');
		addScriptToPage();
	}

	function addScriptToPage(){
		var head = document.getElementsByTagName('head')[0];
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.onload = function() {
			svg4everybody();
		};
		script.src = 'https://cdnjs.cloudflare.com/ajax/libs/svg4everybody/2.1.4/svg4everybody.min.js';
		head.appendChild(script);
	}


};

},{}],32:[function(require,module,exports){
const ObjecHelper = {
	one: function (el, event, callback) {
		function handler(e) {
			callback.call(this, e);
			this.removeEventListener(event, handler);
		}
		el.addEventListener(event, handler);
	}
};

class Tabs {
	constructor(config = {}) {
		this.tabsContainer = document.querySelector(config.el);
		if(!(this.tabsContainer instanceof HTMLElement)) return;
		this.tabAnimation = config.tabAnimation;
		this.tabLinks = Array.prototype.slice.call(this.tabsContainer.querySelector('.nav_tabs').querySelectorAll('.nav_tab_item_link'));
		this.tabs = Array.prototype.slice.call(this.tabsContainer.querySelector('.nav_tabs').querySelectorAll('.nav_tab_item'));
		this.panels = Array.prototype.slice.call(this.tabsContainer.querySelector('.tab_content').querySelectorAll('.tab_pane'));
		this.beforeTabWillBeActive =
			typeof config.beforeTabWillBeActive === 'function'
				? config.beforeTabWillBeActive
				: function () {
				// throw new TypeError('beforeTabWillBeActive should be the Function');
			};
		this.init();
	}
	
	init() {
		this.tabLinks.forEach((el) => {
			el.addEventListener('click', (evt) => {
				evt.preventDefault();
				this.tabOnClick(evt);
			});
		});
	}
	
	tabOnClick(evt) {
		let id = evt.currentTarget.getAttribute('href').slice(1);
		
		let promise  = new Promise((resolve, reject)=>{
			resolve(this.beforeTabWillBeActive(evt));
		});
		
		promise.then(()=>{
			this.setClickedPane(id);
		});
		
		
	}
	
	setClickedPane(id) {
		let link = this.tabsContainer.querySelector('a[href="#' + id + '"]');
		
		if (!link.parentNode.classList.contains('active')) {
			this.tabs.forEach((el) => {
				el.classList.remove('active');
				if (el !== link && el.contains(link)) {
					el.classList.add('active');
				}
			});
			this.panels.forEach((el) => {
				el.classList.remove('active');
				if (el.getAttribute('id') === id) {
					el.classList.add('active');
					el.classList.add(this.tabAnimation);
					ObjecHelper.one.call(this, el, 'animationend', (evt) => {
						el.classList.remove(this.tabAnimation);
					})
				}
			});
		}
		
		
	}
}

module.exports = Tabs;
},{}],33:[function(require,module,exports){
module.exports = function(){
	
	const widget = $('.widget');
	const closeBtn = widget.find('.widget_close_btn');
	setTimeout(()=>{
		widget.addClass('visible');
	}, 17000);
	closeBtn.on('click', function(evt){
		widget.removeClass('visible');
	});
};
},{}],34:[function(require,module,exports){
function tryReachGoal(targetName) {
  try {
    yaCounter42842649.reachGoal(targetName);
  } catch (e) {
    Raven.captureException(e);
  }
}

const reachGoal = function reachGoalAction(targetName) {
  if (document.readyState === 'loading') {
    window.addEventListener('load', () => {
      tryReachGoal(targetName);
    });
  } else {
    tryReachGoal(targetName);
  }
};

module.exports = { reachGoal };

},{}],35:[function(require,module,exports){
module.exports = function () {
  if (!window.ymaps || currentPage !== 'review') {
    return;
  }

  ymaps.ready(init);

  let suggestionBinded = false;
  function init() {
    let suggestView,
      myPlacemark,
      myMap = new ymaps.Map('map', {
        center: [55.753994, 37.622093],
        zoom: 9,
        controls: ['zoomControl'],
      }, {
        searchControlProvider: 'yandex#search',
      });

    /**
		 * Disable scroll on mouse wheel move
		 */

    myMap.behaviors.disable('scrollZoom');

    /**
		 * Update Placemark
		 *
		 * @param coords
		 * @param disableSuggestions
		 */
    function updateOrCreatePlacemark(coords, title) {
      if (myPlacemark) {
        myPlacemark.geometry.setCoordinates(coords);
        myPlacemark.properties.set('iconCaption', title);
      } else {
        myPlacemark = new ymaps.Placemark(coords, { iconCaption: title }, { preset: 'islands#violetDotIconWithCaption', draggable: true });
        myMap.geoObjects.add(myPlacemark);
        myPlacemark.events.add('dragend', clickDragGeocoder);
      }
    }

    function zoomToCoords(coords) {
      const myAction = new ymaps.map.action.Single({
        center: coords,
        zoom: 14,
        duration: 1000,
        timingFunction: 'ease-in',
        checkZoomRange: true,
        callback: (err) => { if (err) { throw err; } },
      });
      myMap.action.execute(myAction);
    }

    function getAddressLineFromGeoObject(firstGeoObject) {
      let displayAddress = firstGeoObject.getThoroughfare() || firstGeoObject.getPremise();
      if (firstGeoObject.getPremiseNumber() !== undefined) {
        displayAddress = `${displayAddress}, ${firstGeoObject.getPremiseNumber()}`;
      }
      if (displayAddress === undefined) {
        displayAddress = firstGeoObject.getAddressLine();
      }
      return displayAddress;
    }

    const suggestionGeocoder = (e) => {
      const myGeocoder = ymaps.geocode(e.get('item').value);
      myGeocoder
        .then((res) => {
          const coords = res.geoObjects.get(0).geometry.getCoordinates();
          updateOrCreatePlacemark(coords, getAddressLineFromGeoObject(res.geoObjects.get(0)));
          zoomToCoords(coords);
          $('#suggest').valid();
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const clickDragGeocoder = (e) => {
      let coords = e.get('coords');
      if (coords === undefined) {
        coords = myPlacemark.geometry.getCoordinates();
      }
      updateOrCreatePlacemark(coords, 'поиск...');
      destroySuggestion();
      ymaps.geocode(coords).then((res) => {
        const firstGeoObject = res.geoObjects.get(0);
        updateOrCreatePlacemark(coords, getAddressLineFromGeoObject(firstGeoObject));
        document.getElementById('suggest').value = firstGeoObject.getAddressLine();
        $('#suggest').valid();
      });
    };

    /**
		 * Bind Suggestion
		 */
    function bindSuggestion() {
      if (!suggestionBinded) {
        suggestionBinded = true;
        suggestView = new ymaps.SuggestView('suggest', {
          results: 10,
        });
        suggestView.events.add('select', suggestionGeocoder);
      }
    }

    /**
		 * Destroy Suggestion
		 */
    function destroySuggestion() {
      suggestionBinded = false;
      if (suggestView) {
        suggestView.destroy();
      }
    }

    // /////////////////////
    // EVENT HANDLERS
    // /////////////////////

    document.getElementById('suggest').onfocus = bindSuggestion;
    myMap.events.add('click', clickDragGeocoder);


    $('.form_label_maps_link').on('click', () => {
      $('#map').css({
        height: '275px',
      });
      $('.form_label_maps_link_wrap').hide();
      myMap.container.fitToViewport();
    });
  }
};

},{}],36:[function(require,module,exports){
module.exports = (setCurrentCity, getCookie, setCookie) => {
  const $yourCity = $('.is_your_city_main');
  const $content = $('.is_your_city_content');
  const $confirmBtn = $yourCity.find('.btn_yes');
  const $closeBtn = $yourCity.find('.btn_no');
  let city;

  if (typeof getCookie('user_city') === 'undefined') {
    $.ajax({
      url: '/geo/get-city-by-ip',
      success: (response) => {
        city = response;
        $content.html(`Ваш город ${city.title}?`);
        $yourCity.addClass('visible');
      },
    });
  }

  $confirmBtn.on('click', () => {
    setCookie('user_city', city.titleSeo);
    $yourCity.removeClass('visible');
    setCurrentCity(city.titleSeo);
  });
  $closeBtn.on('click', () => {
    setCookie('user_city', initialState.citySeo);
    $yourCity.removeClass('visible');
  });
};
},{}]},{},[1])